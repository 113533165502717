@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  color: #333;
}
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 260px;
  background: #282e40;
  z-index: 100;
  transition: all 0.5s ease;
}
.sidebar.close {
  width: 64px;
}
.sidebar .logo-details {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
}
.sidebar .logo-details i {
  font-size: 30px;
  color: #fff;
  height: 50px;
  min-width: 64px;
  text-align: center;
  line-height: 50px;
}
.sidebar .logo-details .logo_name {
  font-size: 22px;
  color: #fff;
  font-weight: 600;
  transition: 0.3s ease;
  transition-delay: 0.1s;
  margin-left: 15px;
}
.sidebar.close .logo-details .logo_name {
  transition-delay: 0s;
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links {
  height: 100%;
  padding: 30px 0 150px 0;
  overflow: auto;
}
.sidebar.close .nav-links {
  overflow: visible;
}
.sidebar .nav-links::-webkit-scrollbar {
  display: none;
}
.sidebar .nav-links li {
  position: relative;
  list-style: none;
  transition: all 0.4s ease;
}
.sidebar .nav-links li:hover {
  background: #1d1b31;
  background: #202330;
  color: #fff;
}
.sidebar .nav-links li .active {
  background: #1d1b31;
  color: #fff;
}
.sidebar .nav-links li .iocn-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebar.close .nav-links li .iocn-link {
  display: block;
}
.sidebar .nav-links li i {
  height: 50px;
  min-width: 64px;
  text-align: center;
  line-height: 50px;
  color: rgba(255, 255, 255, 0.486);
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.sidebar .nav-links li.showMenu i.arrow {
  transform: rotate(-180deg);
}
.sidebar.close .nav-links i.arrow {
  display: none;
}
.sidebar .nav-links li a {
  display: flex;
  align-items: center;
  text-decoration: none;
}
.sidebar .nav-links li a .link_name {
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.64);
  transition: all 0.4s ease;
}
.sidebar.close .nav-links li a .link_name {
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links li:hover i {
  color: #fff !important;
}
.sidebar .nav-links li:hover a .link_name {
  color: #fff !important;
}
.sidebar .nav-links li .sub-menu {
  padding: 6px 6px 14px 80px;
  margin-top: -10px;
  background: #1d1b31;
  background: #202330;
  display: none;
}
.sidebar .nav-links li.showMenu {
  background: #232137;
  background: #202330;
}
.sidebar .nav-links li.showMenu .sub-menu {
  display: block;
}
.sidebar .nav-links li .sub-menu a {
  color: #fff;
  font-size: 15px;
  padding: 5px 0;
  white-space: nowrap;
  opacity: 0.6;
  transition: all 0.3s ease;
}
.sidebar .nav-links li .sub-menu a:hover {
  opacity: 1;
}
.sidebar.close .nav-links li .sub-menu {
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  opacity: 0;
  display: block;
  pointer-events: none;
  transition: 0s;
}
.sidebar.close .nav-links li:hover .sub-menu {
  top: 0;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
}
.sidebar .nav-links li .sub-menu .link_name {
  display: none;
}
.sidebar.close .nav-links li .sub-menu .link_name {
  font-size: 18px;
  opacity: 1;
  display: block;
}
.sidebar .nav-links li .sub-menu.blank {
  opacity: 1;
  pointer-events: auto;
  padding: 3px 20px 6px 16px;
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links li:hover .sub-menu.blank {
  top: 50%;
  transform: translateY(-50%);
}
.sidebar .profile-details {
  position: fixed;
  bottom: 0;
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1d1b31;
  background: #232137d5;
  padding: 12px 0;
  transition: all 0.5s ease;
}
.sidebar.close .profile-details {
  background: none;
}
.sidebar.close .profile-details {
  width: 64px;
}
.sidebar .profile-details .profile-content {
  display: flex;
  align-items: center;
}
.sidebar .profile-details img {
  height: 52px;
  width: 52px;
  object-fit: cover;
  border-radius: 16px;
  margin: 0 14px 0 12px;
  background: #1d1b31;
  background: #232137d5;
  transition: all 0.5s ease;
}
.sidebar.close .profile-details img {
  padding: 10px;
}
.sidebar .profile-details .profile_name,
.sidebar .profile-details .job {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
}
.sidebar.close .profile-details i,
.sidebar.close .profile-details .profile_name,
.sidebar.close .profile-details .job {
  display: none;
}
.sidebar .profile-details .job {
  font-size: 12px;
}
.home-section {
  position: relative;
  background: #f4f6f9;
  height: 100vh;
  left: 260px;
  width: calc(100% - 260px);
  transition: all 0.5s ease;
  overflow: scroll;
}
.sidebar.close ~ .home-section {
  left: 64px;
  width: calc(100% - 64px);
}
.home-section .home-content {
  height: 60px;
  display: flex;
  align-items: center;
}
.home-section .home-content .bx-menu,
.home-section .home-content .text {
  color: #11101d;
  font-size: 35px;
}
.home-section .home-content .bx-menu {
  margin: 0 15px;
  cursor: pointer;
}
.home-section .home-content .text {
  font-size: 26px;
  font-weight: 600;
}
@media (max-width: 600px) {
  .sidebar.close .nav-links li .sub-menu {
    display: none;
  }
  .sidebar {
    width: 64px;
  }
  .sidebar.close {
    width: 0;
  }
  .home-section {
    left: 64px;
    width: calc(100% - 64px);
    z-index: 100;
  }
  .sidebar.close ~ .home-section {
    width: 100%;
    left: 0;
  }
}

.sidebar .logo-details img {
  font-size: 30px;
  color: #fff;
  height: 50px;
  min-width: 50px;
  text-align: center;
  line-height: 50px;
  margin-left: 7px;
}

.h-badge {
  --bs-badge-padding-x: 0.3em;
  --bs-badge-padding-y: 0.15em;
  --bs-badge-font-size: 0.55em;
}

.avatar-md {
  width: 2.5rem;
  height: 2.5rem;
  font-size: 1rem;
}
.avatar {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  border-radius: 50em;
  transition: margin 0.15s;
  width: 2rem;
  height: 2rem;
  font-size: 0.8rem;
}

.avatar-img {
  width: 100%;
  height: auto;
  border-radius: 50em;
}

img, svg {
  vertical-align: middle;
}

.wrapper {
  max-width: 350px;
  min-height: 500px;
  margin: auto;
  padding: 40px 15px 15px 15px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 13px 13px 20px #cbced1, -13px -13px 20px #cbced1;
}

.logo {
  width: 80px;
  margin: auto;
}

.logo img {
  width: 100%;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0px 0px 3px #fff, 0px 0px 0px 5px #ecf0f3, 8px 8px 15px #fff,
    -8px -8px 15px #fff;
}

.wrapper .name {
  font-weight: 600;
  font-size: 1.4rem;
  letter-spacing: 1.1px;
  padding-left: 10px;
  color: #555;
}

.wrapper .form-field .fas {
  color: #555;
}

.wrapper .btn:not(.btn-nostyle) {
  box-shadow: none;
  width: 100%;
  height: 40px;
  background-color: #f43b03;
  color: #fff;
  font-weight: 600;
  border-radius: 15px;
  box-shadow: 3px 3px 3px #b1b1b1, -3px -3px 3px #fff;
  letter-spacing: 1.3px;
}

.btn-custom {
  box-shadow: none;
  width: 100%;
  height: 40px;
  font-weight: 600;
  border-radius: 15px;
  box-shadow: 3px 3px 3px #b1b1b1, -3px -3px 3px #fff;
  letter-spacing: 1.3px;
}

.wrapper .btn:hover {
  background-color: #128c29;
}

.wrapper a {
  text-decoration: none;
  font-size: 0.8rem;
  color: #03a9f4;
}

.wrapper a:hover {
  color: #039be5;
}

@media (max-width: 380px) {
  .wrapper {
    margin: 30px 20px;
    padding: 40px 15px 15px 15px;
  }
}

.form-control {
  border: 2px solid #ced4da;
}

.input-error-msg {
  color: red;
  font-size: 13px;
}

.company:hover {
  border: 1px solid #e8ede9;
  box-shadow: 5px 5px 8px #e8ede9, -5px -5px 8px #e8ede9;
}

.sCompany {
  border: 1px solid #128c29;
  box-shadow: 3px 3px 5px #128c29, -3px -3px 5px #128c29 !important;
}

@media (max-width: 780px) {
  .location_name {
    color: red;
    display: none;
  }
}

.small-box-footer {
  background-color: rgba(0, 0, 0, 0.1);
  color: rgba(255, 255, 255, 0.8);
  display: block;
  padding: 3px 0;
  position: relative;
  text-align: center;
  text-decoration: none;
  z-index: 10;
  cursor: pointer;
}
.small-box-footer:hover,
.small-box-footer:focus,
.small-box-footer:active {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.8) !important;
  font-weight: bold;
}

.det {
  display: flex !important;
}

.recipient-legend {
  display: flex;
  justify-content: center;
}
.recipient-legend .recipient-legend-flag {
  margin-right: -0.5rem;
  width: 2.5rem;
  height: 2.5rem;
  flex: 0 0 2.5rem;
}
.recipient-legend .recipient-legend-avatar {
  margin-left: -0.5rem;
  display: flex;
  text-align: center;
  text-transform: uppercase;
  background: #e4dcd9;
  color: #7a6761;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  flex: 0 0 2.5rem;
  border-radius: 2rem;
}

.odds {
  background: #f4f6f9;
}

.detail-row .row .label {
  margin-bottom: 2px;
}

.detail-row .row .value {
  margin-bottom: 2px;
}

.detail-row .label {
  margin-bottom: 2px;
}

.detail-row .value {
  margin-bottom: 2px;
}

.modal-backdrop {
  display: none;
}

.modal {
  background-color: rgba(10, 10, 10, 0.45);
}

.value {
  font-weight: 600;
}

.rowsep {
  padding: 0.5rem 0.6rem;
  border-top: 1px solid #f4f6f9;
}

.dt-middle {
  vertical-align: middle;
}

.padd-5 {
  padding: 0.5rem;
}

.marg-5 {
  margin: 0.5rem;
}

.bg-alt {
  background: #f4f6f9;
}

.ft-wt-6 {
  font-weight: 600;
}

.marg-btm-5 {
  margin-bottom: 0.5rem;
}

fieldset, legend {
  all: revert;
}

fieldset {
  border-color: white;
}

form p {
  margin: 2px;
}

.btn-in-div:hover {
  box-shadow: 5px 5px 8px #e8ede9, -5px -5px 8px #e8ede9;
}

.marg-btm {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-bottom: 0;
}

.css-tj5bde-Svg {
  width: 15px !important;
  height: 15px !important;
}

.css-1xc3v61-indicatorContainer {
  padding: 3px;
}

.css-15lsz6c-indicatorContainer {
  padding: 3px;
}

.md-image-text-item {
  position: relative;
  padding: 15px 0 15px 50px;
  text-align: left;
  line-height: normal;
}

.md-image-text-avatar {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 0;
  top: 5px;
}

.css-1fdsijx-ValueContainer {
  padding: 8px
}

.form-label {
  margin-bottom: 0;
}
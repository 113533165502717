/* @tailwind base; */
@tailwind components;
@tailwind utilities;

.step-item {
    @apply tw-relative tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-1/3;
  }
.step-item:not(:first-child):before {
  @apply tw-content-[''] tw-bg-slate-200 tw-absolute tw-w-full tw-h-[3px] tw-right-2/4 tw-top-1/4 tw--translate-y-2/4;
}
.step {
  @apply tw-w-10 tw-h-10 tw-flex tw-items-center tw-justify-center tw-z-10 tw-relative tw-bg-slate-700 tw-rounded-full tw-font-semibold tw-text-white;
}
.active .step {
  @apply tw-bg-sky-600;
}
.complete .step {
  @apply tw-bg-green-600;
}
.complete p {
  @apply tw-text-white;
}
.complete:not(:first-child):before,
.active:not(:first-child):before {
  @apply tw-bg-green-600;
}
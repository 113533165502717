@keyframes spinner {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  }

  .spinner-container {
	  /* top: 0;
	  left: 0;
    width: 100%;
    height: 100vh;
    position: fixed;
    background: rgba(0, 0, 0, 0.834)
        url("https://media.giphy.com/media/8agqybiK5LW8qrG3vJ/giphy.gif") center
        no-repeat;
    z-index: 150; */
	top: 0;
	left: 0;
	width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
	flex-direction: column;
    align-items: center;
    position: fixed;
    background: rgba(0, 0, 0, 0.634);;
    z-index: 150;
}

  .loading-spinner {
	width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: #3d5af1 transparent #3d5af1 transparent;
    border-radius: 50%;
    /* animation: spin-anim 1.2s linear infinite; */
  }